import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import classnames from 'classnames'

import LoginImage from '../assets/HPI_AI_product_promo FI.png'
import HeerosLogo from '../assets/Heeros-logo.png'

const imgStyle = { maxWidth: '100%', objectFit: 'cover' }
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    background: '#fff'
  },
  fullHeight: {
    minHeight: '100%'
  },
  imageStyles: {
    minHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  contentSection: {
    padding: theme.spacing(3)
  },
  imageSection: {
    backgroundImage: `url(${LoginImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  logoContainer: {
    alignSelf: 'flex-start'
  },
  logo: {
    height: theme.typography.fontSize * 1.5
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 2,
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: '25vw'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80vw'
    }
  }
}))

const MainLayout = props => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container className={classes.fullHeight}>
        <Grid
          item
          md={6} lg={6} xl={6} xs={12} sm={12}
          className={classnames(classes.fullHeight, classes.contentSection)}
        >
          <Grid container direction='column' alignItems='center' className={classes.fullHeight}>
            <Grid item className={classes.logoContainer}>
              <a href='https://heeros.com'>
                <img
                  src={HeerosLogo}
                  alt='Heeros'
                  className={classes.logo}
                  data-test='heeros-logo'
                />
              </a>
            </Grid>
            <Grid item className={classes.contentContainer}>
              {props.children}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={6} lg={6} xl={6} xs={false} sm={false}
        >
          {/* eslint-disable-next-line */}
          <a href='https://www.heeros.com/ajankohtaista/ai-tiliointi-taydentaa-heeros-ostolaskut-ratkaisua' target='_blank' rel='noopener'>
            <img
              style={imgStyle}
              src={LoginImage}
              alt=''
              className={classes.imageStyles}
              data-test='heeros-logo'
            />
          </a>
        </Grid>
      </Grid>
    </div>
  )
}

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
}

export default MainLayout
